<template>
  <el-container class="h-full">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Sys Telephony')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <component v-bind:is="component"></component>
  </el-container>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "SysTelephonyIndex",
  components: {
    PageHeader
  },

  data() {
    return {
      selectedComponent: "sys-phone-numbers",
      selectedIndex: null,
      scope: "sys",
      navItems: []
    };
  },

  mounted() {
    let navItems = [
      {
        label: __("Phone Numbers"),
        active: true,
        path: "sys-phone-numbers",
        icon: "icon-voice.svg"
      }
    ];

    if (!this.ctEnabled) {
      navItems.push({
        label: __("Trunks"),
        active: false,
        path: "sys-trunks",
        icon: "icon-voice.svg"
      });
    }
    this.navItems = navItems;
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    },

    ...mapGetters("app", {
      ctEnabled: "ctEnabled"
    })
  },

  methods: {
    importComponent(path) {
      return () =>
        import("@/views/system/sys-telephony/" + path + "/index.vue");
    },

    handleNavItemChanged(navItem) {
      this.selectedComponent = navItem.path;
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
